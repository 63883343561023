<div class="registration">
    <div class="registration__form">
        <div class="registration__form__header">
            <h2>{{'REGISTER.REGISTRATION' | translate}}</h2>
        </div>
        <div class="registration__form__fields">

            <form [formGroup]="registrationFormGroup">

                <div class="registration__form__fields__select-field">
                    <app-fnp-select
                            appFeatureFlag="registrationMarketSelect"
                            formControlName="marketId"
                            [label]="'REGISTER.MARKET'"
                            [labelPropertyName]="'name'"
                            [valuePropertyName]="'id'"
                            [selectOptions]="availableMarkets"
                    ></app-fnp-select>
                    <div *ngIf="marketId?.invalid && (marketId?.dirty || marketId?.touched)"
                         class="registration__form__fields__field__error">
                        <div *ngIf="marketId?.errors?.['required']">
                            {{'REGISTER.MARKET_REQUIRED' | translate}}
                        </div>
                    </div>
                </div>

                <div class="registration__form__fields__field">
                    <label for="finCode" class="registration__form__fields__field__label">
                        {{'REGISTER.FIN_CODE' | translate}}
                    </label>
                    <input
                            lettersNumbersOnly
                            id="finCode"
                            type="text"
                            class="form-control registration__form__fields__field__input"
                            formControlName="finCode">
                    <div *ngIf="finCode.invalid && (finCode.dirty || finCode.touched)"
                         class="registration__form__fields__field__error">
                        <div *ngIf="finCode.errors?.['required']">
                            {{'REGISTER.FIN_CODE_REQUIRED' | translate}}
                        </div>
                    </div>
                </div>

                <div class="registration__form__fields__field">
                    <label for="contactName" class="registration__form__fields__field__label">
                        {{'REGISTER.CONTACT_NAME' | translate}}
                    </label>
                    <input
                            id="contactName"
                            type="text"
                            class="form-control registration__form__fields__field__input"
                            formControlName="contactName">
                    <div *ngIf="contactName.invalid && (contactName.dirty || contactName.touched)"
                         class="registration__form__fields__field__error">
                        <div *ngIf="contactName.errors?.['required']">
                            {{'REGISTER.CONTACT_NAME_REQUIRED' | translate}}
                        </div>
                    </div>
                </div>

                <div class="registration__form__fields__field">
                    <label for="email" class="registration__form__fields__field__label">
                        {{'REGISTER.EMAIL_ADDRESS' | translate}}
                    </label>
                    <input
                            id="email"
                            type="text"
                            class="form-control registration__form__fields__field__input"
                            formControlName="email">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)"
                         class="registration__form__fields__field__error">
                        <div *ngIf="email.errors?.['required']">
                            {{'REGISTER.EMAIL_ADDRESS_REQUIRED' | translate}}
                        </div>
                        <div *ngIf="email.errors?.['email']">
                            {{'REGISTER.EMAIL_ADDRESS_INCORRECT' | translate}}
                        </div>
                    </div>
                </div>

            </form>

        </div>
        <div class="registration__form__buttons">

            <button class="btn fnp-btn-secondary" type="submit"
                    (click)="toLoginPage()">{{'REGISTER.CANCEL' | translate}}</button>
            <button class="btn fnp-btn-primary" type="submit"
                    (click)="onSubmit()"
                    [disabled]="!registrationFormGroup.valid">{{'REGISTER.REGISTER' | translate}}</button>

        </div>

    </div>
</div>

<app-dialog #successfullyRegistered (submit$)="toLoginPage()" [params]="successParams"></app-dialog>
<app-dialog #wrongFinCode (submit$)="resetForm()" [params]="wrongFinParams"></app-dialog>
<app-dialog #emailRegistered (submit$)="resetForm()" [params]="emailRegisteredParams"></app-dialog>
<app-dialog #generalError [params]="generalErrorParams"></app-dialog>
