import {inject, Injectable} from '@angular/core';
import {MarketService} from './market.service';
import {FeatureFlagI} from '../models/feature-flag-i';
import {v4 as uuid} from 'uuid';
import {FEATURE_FLAG_MAP} from '../../config/markets/feature-flags';
import {Subject} from 'rxjs';
import {StateObservable} from '../utils/state-observable/state-observable';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService extends StateObservable {

    private _marketService: MarketService = inject(MarketService);
    private registeredFlags: FeatureFlagI[] = [];

    constructor() {
        super();
        this._marketService.stateUpdate.subscribe({
            next: () => this.trigger()
        });
    }

    public register(name: string): Subject<void> {
        this.registeredFlags.push({
            id: uuid(),
            name,
            disabled: false
        });
        return this.stateUpdate;
    }

    public getActiveState(flagName: string): boolean {
        for (const marketId of (FEATURE_FLAG_MAP.get(flagName) ?? [])) {
            if (marketId === this._marketService.currentMarket.id) return true;
        }
        return false;
    }

}
