import {HttpHeaders} from '@angular/common/http';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export function getFilenameFromHeaders(headers: HttpHeaders): string {
    return headers.get('Content-Disposition')
        .split(';')
        .find(prop => prop.includes('filename'))
        .split('=')[1]
        .trim()
        .replaceAll('"', '');
}

export function downloadFileAction(body: ArrayBuffer, fileName: string, extension: string): void {
    const blob = new Blob([body], {type: extension});
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = URL.createObjectURL(blob);
    anchor.click();
}

export function getMonthTranslationKey(currentMonth: number): string {
    switch (currentMonth) {
        case 0:
            return 'DATE.JANUARY';
        case 1:
            return 'DATE.FEBRUARY';
        case 2:
            return 'DATE.MARCH';
        case 3:
            return 'DATE.APRIL';
        case 4:
            return 'DATE.MAY';
        case 5:
            return 'DATE.JUNE';
        case 6:
            return 'DATE.JULY';
        case 7:
            return 'DATE.AUGUST';
        case 8:
            return 'DATE.SEPTEMBER';
        case 9:
            return 'DATE.OCTOBER';
        case 10:
            return 'DATE.NOVEMBER';
        case 11:
            return 'DATE.DECEMBER';
        default:
            return 'DATE.MONTH';
    }
}

export function getMessageFromError(error: any): string {
    const ERR_CODE_PREFIX = 'EC';
    if (typeof error === 'string' && error.startsWith(ERR_CODE_PREFIX)) return error;
    if (typeof error.error === 'string' && error.error.startsWith(ERR_CODE_PREFIX)) return error.error;
    if (typeof error.message === 'string' && error.message.startsWith(ERR_CODE_PREFIX)) return error.message;
    if (typeof error.error?.message === 'string' && error.error?.message.startsWith(ERR_CODE_PREFIX)) return error.error.message;
    return 'EC0000';
}


export function getDatepickerStructFromStringDate(stringDate: any): string {
    const dateObj = new Date(stringDate);
    let day: string | number = dateObj.getDate();
    if (day < 10) day = '0' + day;
    let month: string | number = dateObj.getMonth() + 1;
    if (month < 10) month = '0' + month;
    return `${day}/${month}/${dateObj.getFullYear()}`;
}

export function getNgbDateStructFromTimestamp(timestamp: number): NgbDateStruct {
    const dateObj = new Date(timestamp);
    return {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate()};
}

export function getJavaTimestampFromNgbDateStruct(datepickerObj: NgbDateStruct): number {
    const dateObj = new Date(datepickerObj.year, datepickerObj.month - 1, datepickerObj.day);
    const localTime = dateObj.getTime();
    const millisecondsOffset = dateObj.getTimezoneOffset() * 60 * 1000;
    return Math.round((localTime - millisecondsOffset) / 1000);
}

export function isEdgeBrowser(): boolean {
    const userAgent = window.navigator.userAgent;
    return userAgent.includes('Edg') || userAgent.includes('Edge');
}