<div class="print-container">
    <img class="print-container__logo"
         src="assets/img/logo-ford.png"
         alt="Ford logo">
    <div class="print-container__tables">
        <div class="print-container__tables__vehicle">
         <span class="fnp__job-info__displayed__service-main__header__clickable__txt">
             {{ "FNP.VEHICLE_INFO" | translate }}
         </span>
            <div class="print-container__tables__vehicle__vin">
                <span class="print-container__tables__vehicle__vin__title">
                {{ 'FNP.VIN' | translate }}:</span>
                <span>{{ this.currentVin }}</span>
            </div>

            <ng-container
                    *ngTemplateOutlet="vehicleTable"
                    class="print-container__vehicle__table"
            ></ng-container>
        </div>
        <div class="print-container__info">
            <span>{{ "FNP.PRICES_SHOWN" | translate }}</span>
        </div>
        <div
                *ngFor="let tableTemplate of dataTableArray; let index=index"
                [ngSwitch]="index"
                class="print-container__tables__jobs">
            <span *ngSwitchCase="0"
                  class="fnp__job-info__displayed__service-main__header__clickable__txt">{{ "FNP.SERVICE_MAINTENANCE" | translate }}</span>
            <span *ngSwitchCase="1"
                  class="fnp__job-info__displayed__service-main__header__clickable__txt">{{ "FNP.EXTENDED_MAINTENANCE" | translate }}</span>
            <span *ngSwitchCase="2"
                  class="fnp__job-info__displayed__service-main__header__clickable__txt">{{ "FNP.REPAIR" | translate }}</span>
            <ng-container
                    *ngTemplateOutlet="tableTemplate"
                    class="print-container__tables__jobs__table"
            ></ng-container>
        </div>
    </div>
</div>