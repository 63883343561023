<div class="privacy-policy">
    <div class="privacy-policy__header">
        <h2>{{'PRIVACY.LINKS_TO_PRIVACY_POLICY' | translate}}</h2>
    </div>
    <div class="privacy-policy__flags">
        <a
                *ngFor="let market of availableMarkets"
                class="privacy-policy__flags__flag"
                target="_blank"
                [href]="market.privacyPolicyUrl"
                [ngStyle]="{'width': getStyle()}"
        >
            <img
                    [id]="market.shortname + 'Flag'"
                    [src]="'assets/img/'+ market.shortname!.toLowerCase() +'-flag.png'"
                    [alt]="market.name + ' logo'">
            <label [for]="market.shortname + 'Flag'">{{('GLOBAL.' + market.name.toUpperCase())  | translate}}</label>
        </a>
    </div>
    <div class="privacy-policy__action">
        <button
                type="button"
                class="fnp-btn-secondary btn"
                (click)="back()"
        >{{"GLOBAL.BACK" | translate}}</button>
    </div>
</div>