import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JobInformation} from '../models/job-information';
import {environment} from '../../config/environments/environment';
import {REQUESTED_JOB_INFORMATION_TYPE} from '../utils/enums/job-information';
import {FnpLanguageService} from './fnp-language.service';

@Injectable({
    providedIn: 'root'
})
export class JobInformationService {

    private _httpClient: HttpClient = inject(HttpClient);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public getServiceJobInformation(vin: string, jobInfoType: REQUESTED_JOB_INFORMATION_TYPE, lang?: string): Observable<JobInformation[]> {
        if (!lang) lang = this._languageService.currentLang;
        return this._httpClient.get<JobInformation[]>(`${environment.apiUrl}/job-data?vin=${vin}&type=${jobInfoType}&language=${lang}`);
    }
}
