import {Component, inject, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {FnpFile, FnpFileI, UpdateFnpFile, UploadFnpFile} from '../../../models/fnp-file';
import {FileService} from '../../../services/file.service';
import {DialogService} from '../../../services/dialog.service';
import {
    downloadFileAction,
    getDatepickerStructFromStringDate,
    getJavaTimestampFromNgbDateStruct,
    getMessageFromError,
    getNgbDateStructFromTimestamp
} from '../../../utils/other/utilities';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {dateRangeValidator} from '../../../utils/validators/date-range-validator';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {


    public serviceFilesData: FnpFileI[];
    public repairFilesData: FnpFileI[];
    public newsletterFilesData: FnpFileI[];
    public serviceFileType = '.xlsx';
    public repairFileType = '.xlsx';
    public newsletterFileType = '.pdf';
    public serviceErrorMessage: { message: string };
    public repairErrorMessage: { message: string };
    public newsletterErrorMessage: { message: string };
    public dateRangeModalErrorMessage: { message: string };
    public dateRangeFormGroup: FormGroup;

    private currentModal?: NgbModalRef;
    private _fileService: FileService = inject(FileService);
    private _modalService: NgbModal = inject(NgbModal);
    private _translateService: TranslateService = inject(TranslateService);
    private _dialogService: DialogService = inject(DialogService);
    private _formBuilder: FormBuilder = inject(FormBuilder);

    public ngOnInit(): void {
        this.fetchFiles();
    }

    private fetchFiles(): void {
        this._fileService.getServiceFiles()
            .subscribe({
                next: (response) => {
                    this.serviceFilesData = response;
                },
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
        this._fileService.getRepairFiles()
            .subscribe({
                next: (response) => {
                    this.repairFilesData = response;
                },
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
        this._fileService.getNewsletterFiles()
            .subscribe({
                next: (response) => {
                    this.newsletterFilesData = response;
                },
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public uploadServiceFile(uploadFileObj: UploadFnpFile) {
        delete this.serviceErrorMessage;
        this._fileService.uploadServiceFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp)
            .subscribe({
                next: () => {
                    this.serviceFilesData.push(new FnpFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp));
                    this._modalService.dismissAll();
                },
                error: (error) => this.serviceErrorMessage = {message: getMessageFromError(error)}
            });
    }

    public uploadRepairFile(uploadFileObj: UploadFnpFile) {
        delete this.repairErrorMessage;
        this._fileService.uploadRepairFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp)
            .subscribe({
                next: () => {
                    this.repairFilesData.push(new FnpFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp));
                    this._modalService.dismissAll();
                },
                error: (error) => this.repairErrorMessage = {message: getMessageFromError(error)}
            });
    }

    public uploadNewsletterFile(uploadFileObj: UploadFnpFile) {
        delete this.newsletterErrorMessage;
        this._fileService.uploadNewsletterFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp)
            .subscribe({
                next: () => {
                    this.newsletterFilesData.push(new FnpFile(uploadFileObj.file, uploadFileObj.publicationDateTimestamp));
                    this._modalService.dismissAll();
                },
                error: (error) => this.newsletterErrorMessage = {message: getMessageFromError(error)}
            });
    }

    public deleteServiceFile(fnpFile: FnpFileI): void {
        this._fileService.deleteServiceFile(fnpFile.fileName)
            .subscribe({
                next: () => this.removeFileFromTable(fnpFile.fileName, this.serviceFilesData),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public deleteRepairFile(fnpFile: FnpFileI): void {
        this._fileService.deleteRepairFile(fnpFile.fileName)
            .subscribe({
                next: () => this.removeFileFromTable(fnpFile.fileName, this.repairFilesData),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public deleteNewsletterFile(fnpFile: FnpFileI): void {
        this._fileService.deleteNewsletterFile(fnpFile.fileName)
            .subscribe({
                next: () => this.removeFileFromTable(fnpFile.fileName, this.newsletterFilesData),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public updateServiceFile(updateFnpFile: UpdateFnpFile): void {
        this._fileService.patchServiceFile(updateFnpFile.fileName, updateFnpFile.publicationDateTimestamp)
            .subscribe({
                next: () => this.updateFileInTable(updateFnpFile.fileName, this.serviceFilesData, updateFnpFile.publicationDateTimestamp),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public updateRepairFile(updateFnpFile: UpdateFnpFile): void {
        this._fileService.patchRepairFile(updateFnpFile.fileName, updateFnpFile.publicationDateTimestamp)
            .subscribe({
                next: () => this.updateFileInTable(updateFnpFile.fileName, this.repairFilesData, updateFnpFile.publicationDateTimestamp),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    public updateNewsletterFile(updateFnpFile: UpdateFnpFile): void {
        this._fileService.patchServiceFile(updateFnpFile.fileName, updateFnpFile.publicationDateTimestamp)
            .subscribe({
                next: () => this.updateFileInTable(updateFnpFile.fileName, this.newsletterFilesData, updateFnpFile.publicationDateTimestamp),
                error: (error) => this.displayServerError(getMessageFromError(error))
            });
    }

    private removeFileFromTable(fileName: string, collection: FnpFileI[]): void {
        for (let idx = 0; idx < collection.length; idx++) {
            if (fileName === collection[idx].fileName) {
                collection.splice(idx, 1);
                break;
            }
        }
    }

    private updateFileInTable(fileName: string, collection: FnpFileI[], publicationDateTimestamp: number): void {
        // Multiplied by 1000 as TS Date constructor expect milliseconds.
        const dateObj = new Date(publicationDateTimestamp * 1000);
        for (let idx = 0; idx < collection.length; idx++) {
            if (fileName === collection[idx].fileName) {
                collection[idx].publicationDate = dateObj;
                break;
            }
        }
    }

    public openDownloadReportModal(modalTemplate: any): void {
        this.initDataRangeForm();
        this.currentModal = this._modalService.open(modalTemplate, {
            backdrop: 'static',
            centered: true,
            keyboard: true
        });
        this.currentModal.dismissed.subscribe(() => delete this.dateRangeModalErrorMessage);
    }

    private initDataRangeForm(): void {
        this.dateRangeFormGroup = this._formBuilder.group({
            from: [getNgbDateStructFromTimestamp(Date.now()), []],
            to: [getNgbDateStructFromTimestamp(Date.now() + 24 * 60 * 60 * 1000), []]
        }, {validators: dateRangeValidator()});
    }

    public downloadReport(): void {
        const from = getJavaTimestampFromNgbDateStruct(this.fromFormCtrl.value);
        const to = getJavaTimestampFromNgbDateStruct(this.toFormCtrl.value);

        const element = <HTMLInputElement>document.getElementById('rep-btn');
        element.disabled = true;
        this._fileService.getTransactionReport(from, to).subscribe({
            next: (r) => {
                downloadFileAction(r.body, this._translateService.instant('ADMIN.FNP_TRANSACTION_REPORT') + '.csv', '.csv');
                this.currentModal.close();
            },
            error: (error) => this.displayServerError(getMessageFromError(error))
        });
        element.disabled = false;
    }

    private displayServerError(errorMessage: string): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            'GLOBAL.ERROR_HAS_OCCURRED',
            null,
            'ERROR.' + errorMessage
        );
        this._dialogService.openInfoDialog(dialogContent);
    }

    public getDatepickerStruct(stringDate: any): string {
        return getDatepickerStructFromStringDate(stringDate);
    }

    public get fromFormCtrl(): AbstractControl {
        return this.dateRangeFormGroup.get('from');
    }

    public get toFormCtrl(): AbstractControl {
        return this.dateRangeFormGroup.get('to');
    }

}
