import { Component } from '@angular/core';

@Component({
  selector: 'app-introduction-and-map',
  templateUrl: './introduction-and-map.component.html',
  styleUrls: ['./introduction-and-map.component.scss']
})
export class IntroductionAndMapComponent {

}
