<div class="select-field">
    <fa-icon
            class="select-field__icon"
            [icon]="['fas', 'chevron-down']"
    ></fa-icon>
    <label for="selectI" class="select-field__label">
        {{label | translate}}
    </label>
    <select
            id="selectI"
            class="form-control select-field__input"
            [formControl]="formControlDirective.control"
    >
        <option
                *ngFor="let market of selectOptions"
                class="select-field__input__option"
                [value]="market[valuePropertyName]"
        >{{market[labelPropertyName] | translate}}</option>
    </select>
</div>