import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Market, MarketPropName} from '../models/market';
import {AVAILABLE_MARKETS, DEFAULT_MARKET} from '../../config/markets/available-markets';
import {StateObservable} from '../utils/state-observable/state-observable';

@Injectable({
    providedIn: 'root'
})
export class MarketService extends StateObservable {
    private _currentMarket$: BehaviorSubject<Market>;

    public setMarketById(marketId: number): void {
        this.setMarketBy('id', marketId);
    }

    private setMarketBy(marketParam: MarketPropName, marketParamValue: number | string): void {
        let market = AVAILABLE_MARKETS.find(m => m[marketParam] == marketParamValue);
        if (!market) market = DEFAULT_MARKET;
        this.market = market;
    }

    private set market(market: Market) {
        if (!this._currentMarket$) {
            this._currentMarket$ = new BehaviorSubject<Market>(market);
        } else {
            this._currentMarket$.next(market);
        }
        this.trigger();
        // TODO remove after debug
        console.log('CURRENT MARKET:  ' + this._currentMarket$.getValue().name);
        console.log(this._currentMarket$.getValue());
    }

    public get currentMarket(): Market {
        if (!this._currentMarket$ || !this._currentMarket$?.getValue()) {
            this.market = DEFAULT_MARKET;
        }
        return this._currentMarket$.getValue();
    }

    public get privacyPolicyUrl(): string {
        return this.currentMarket.privacyPolicyUrl;
    }

    public get currencySymbol(): string {
        return this.currentMarket.currencySymbol;
    }

}
