import {Component, inject} from '@angular/core';
import {AVAILABLE_MARKETS} from '../../../../config/markets/available-markets';
import {ActivatedRoute, Router} from '@angular/router';
import {FnpLanguageService} from '../../../services/fnp-language.service';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

    public availableMarkets = AVAILABLE_MARKETS;

    private _router: Router = inject(Router);
    private _route: ActivatedRoute = inject(ActivatedRoute);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    constructor() {
        this.setLocale();
    }

    private setLocale(): void {
        const lang = this._route.snapshot.queryParams['lang'];
        setTimeout(() => this._languageService.use(lang), 300);
    }

    public back(): void {
        this._router.navigate(['login']);
    }

    public getStyle(): string {
        return 'calc((100vw - 8rem - ' + ((this.availableMarkets.length - 1) * 2.5) +
            'rem)/' + this.availableMarkets.length + ')';
    }
}
