import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';
import {Vehicle} from '../models/vehicle';
import {FnpLanguageService} from './fnp-language.service';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    private _httpClient: HttpClient = inject(HttpClient);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public getVehicleByVin(vin: string, lang?: string): Observable<Vehicle> {
        if(!lang)lang = this._languageService.currentLang;
        return this._httpClient.get<Vehicle>(environment.apiUrl + `/vehicle-data?vin=${vin}&language=${lang}`);
    }
}
