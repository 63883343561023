import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AVAILABLE_LANGUAGES} from '../../assets/i18n/available-languages';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class FnpLanguageService {

    public updateLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private readonly CACHED_LANGUAGE = 'CACHED_LANGUAGE';
    private _translateService: TranslateService = inject(TranslateService);
    private _storageService: StorageService = inject(StorageService);

    public translate(key: string): string {
        return this._translateService.instant(key);
    }

    public use(langCode: string): Observable<any> {
        const validLang: string = AVAILABLE_LANGUAGES.find(lang => lang.code?.toLowerCase() === langCode?.toLowerCase())?.code
            ?? this._translateService.getDefaultLang();
        this.updateLanguage$.next(validLang);
        this._storageService.setStored(this.CACHED_LANGUAGE, langCode);
        return this._translateService.use(validLang);
    }

    public get currentLang(): string {
      return this._translateService.currentLang ?? this.cachedLanguage ?? this._translateService.getDefaultLang();
    }

    public get cachedLanguage(): string {
        return this._storageService.getStored(this.CACHED_LANGUAGE);
    }
}
