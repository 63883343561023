import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private httpClient = inject(HttpClient);

  public isNewsletterRead(email: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiUrl}/users/${email}/newsletter-read`);
  }

  public setNewsletterRead(email: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiUrl}/users/${email}/newsletter-read`, true);
  }

  //TODO market id temporary hardcoded, to be replaced when implemented for other markets
  public getNewsletterFile(): Observable<HttpResponse<ArrayBuffer>> {
    return this.httpClient.get<ArrayBuffer>(`${environment.apiUrl}/newsletters?status=ok`,
      {responseType: 'blob' as 'json', observe: 'response'});
  }
}
