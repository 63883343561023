<footer class="fnp-footer">
    <div class="fnp-footer__rights">
        <span>{{"FOOTER.OEC_ALL_RIGHTS_RESERVED" | translate}}</span>
    </div>
    <div class="fnp-footer__links">
        <a href="mailto:FPSA.Support@oeconnection.com?subject={{('FOOTER.HELP_DESK_EMAIL_SUBJECT_FOR_LOGGED' | translate) + ' ' + (userName ?? '')}}"
           target="_blank">{{"FOOTER.HELP_DESK" | translate}}</a>
        <span *ngIf="!isCurrentRoutePrivacyPolicy" class="fnp-footer__links__spacer">|</span>
        <a
                *ngIf="!isCurrentRoutePrivacyPolicy"
                (click)="redirectToPrivacyPolicy()"
        >{{"FOOTER.PRIVACY_POLICY" | translate}}</a>
    </div>
</footer>