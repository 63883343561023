<div class="users">
    <div class="users__title">
        <h2>{{'USERS.USER_MANAGEMENT' | translate}}</h2>
    </div>
    <div class="users__actions">
        <form
                class="users__actions__filters"
                [formGroup]="filtersFormGroup"
                (ngSubmit)="applyFilters()">
            <div class="users__actions__filters__inputs">
                <input
                        lettersNumbersOnly
                        id="finCode"
                        type="text"
                        class="form-control"
                        formControlName="finCode"
                        [placeholder]="'USERS.FIN_CODE' | translate">
                <input
                        id="company"
                        type="text"
                        class="form-control"
                        formControlName="companyName"
                        [placeholder]="'USERS.COMPANY' | translate">
                <input
                        id="email"
                        class="form-control"
                        formControlName="email"
                        [placeholder]="'USERS.EMAIL' | translate">
                <input
                        id="contact"
                        type="text"
                        class="form-control"
                        formControlName="contactName"
                        [placeholder]="'USERS.CONTACT' | translate">
            </div>
            <div class="users__actions__filters__buttons">
                <button
                        type="submit"
                        class="btn fnp-btn-primary"
                        [disabled]="filtersFormGroup.invalid"
                >{{"USERS.SEARCH" | translate}}</button>
                <button
                        type="button"
                        class="btn fnp-btn-secondary"
                        (click)="resetFilters()"
                >{{"USERS.RESET" | translate}}</button>
            </div>
        </form>
        <div class="users__actions__add">
            <button
                    type="submit"
                    class="btn fnp-btn-primary"
                    (click)="openUserModal(userModal)"
            >{{"USERS.ADD_USER" | translate}}</button>
            <button
                    type="button"
                    class="btn fnp-btn-primary"
                    (click)="openCompanyModal(companyModal)"
            >{{"USERS.ADD_COMPANY" | translate}}</button>
            <button
                    type="button"
                    class="btn fnp-btn-tertiary"
                    [disabled]="checkboxSet.size <1"
                    (click)="deleteUsers()"
            >{{"USERS.DELETE" | translate}}</button>
        </div>
    </div>
    <div class="users__info">
        <span>{{('USERS.SHOWING' | translate) + ' ' + currentPages + ' ' + ('USERS.OF' | translate) + ' ' + recordsCount}}</span>
    </div>
    <div class="users__table">
        <table class="table fnp-stand-tbl">
            <thead>
            <tr>
                <th class="cell-width--5">
                    <input
                            name="toggleAllCheckbox"
                            type="checkbox"
                            class="fnp-checkbox"
                            (click)="toggleAll()">
                </th>
                <th
                        class="cell-width--20"
                        [ngClass]="{'sorted-header': searchCriteria.sortBy?.sortColumn === 'company.fin'}"
                        (click)="sortBy('company.fin')"
                >
                    <span>{{"USERS.FIN_CODE" | translate}}</span>
                    <fa-icon
                            *ngIf="searchCriteria.sortBy?.sortColumn === 'company.fin'"
                            class="ms-3"
                            [icon]="['fas',  getSortDirection()]"
                    ></fa-icon>
                </th>
                <th
                        class="cell-width--20"
                        [ngClass]="{'sorted-header': searchCriteria.sortBy?.sortColumn === 'company.name'}"
                        (click)="sortBy('company.name')"
                >
                    <span>{{"USERS.COMPANY_NAME" | translate}}</span>
                    <fa-icon
                            *ngIf="searchCriteria.sortBy?.sortColumn === 'company.name'"
                            class="ms-3"
                            [icon]="['fas',  getSortDirection()]"
                    ></fa-icon>
                </th>
                <th
                        class="cell-width--30"
                        [ngClass]="{'sorted-header': searchCriteria.sortBy?.sortColumn === 'email'}"
                        (click)="sortBy('email')"
                >
                    <span>{{"USERS.EMAIL_ADDRESS" | translate}}</span>
                    <fa-icon
                            *ngIf="searchCriteria.sortBy?.sortColumn === 'email'"
                            class="ms-3"
                            [icon]="['fas',  getSortDirection()]"
                    ></fa-icon>
                </th>
                <th
                        class="cell-width--20"
                        [ngClass]="{'sorted-header': searchCriteria.sortBy?.sortColumn === 'contactName'}"
                        (click)="sortBy('contactName')"
                >
                    <span>{{"USERS.CONTACT_NAME" | translate}}</span>
                    <fa-icon
                            *ngIf="searchCriteria.sortBy?.sortColumn === 'contactName'"
                            class="ms-3"
                            [icon]="['fas',  getSortDirection()]"
                    ></fa-icon>
                </th>
                <th
                        class="cell-width--15"
                        [ngClass]="{'sorted-header': searchCriteria.sortBy?.sortColumn === 'administrator'}"
                        (click)="sortBy('administrator')"
                >
                    <span>{{"USERS.ADMINISTRATOR" | translate}}</span>
                    <fa-icon
                            *ngIf="searchCriteria.sortBy?.sortColumn === 'administrator'"
                            class="ms-3"
                            [icon]="['fas',  getSortDirection()]"
                    ></fa-icon>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of usersData">
                <td class="cell-width--5"><input
                        type="checkbox"
                        class="fnp-checkbox"
                        [name]="'checkbox_' + user.id"
                        (click)="toggleSingle(user.id)">
                </td>
                <td class="cell-width--15">{{user.finCode}}</td>
                <td class="cell-width--20">{{user.companyName}}</td>
                <td class="cell-width--30">{{user.email}}</td>
                <td class="cell-width--20">{{user.contactName}}</td>
                <td class="cell-width--10">{{ "USERS." + (user.administrator ? 'YES' : 'NO') | translate}}</td>
            </tr>
            </tbody>
        </table>
        <app-fnp-paginator
                style="width: 100%;"
                [pageable]="searchCriteria.pageable"
                (reloadPage$)="pageReloadTrigger()"
        ></app-fnp-paginator>
    </div>
</div>

<ng-template #companyModal>
    <app-fnp-modal
            [title]="'USERS.ADD_COMPANY'"
            [contentTemplate]="companyModalContent"
            [saveAction]="true"
            [modalError]="modalError"
            [invalidForm]="companyFormGroup.invalid"
            (submitAction$)="addCompany()"
    ></app-fnp-modal>
</ng-template>

<ng-template #companyModalContent>
    <form
            class="company-modal"
            [formGroup]="companyFormGroup">
        <h2 class="company-modal__group-title">{{'USERS.COMPANY_DETAILS' | translate}}</h2>
        <div class="company-modal__field mb-4">
            <label
                    for="finCodeI"
                    class="my-details__form__fields__field__label"
            >{{'USERS.FIN_CODE' | translate}}</label>
            <input
                    lettersNumbersOnly
                    id="finCodeI"
                    class="form-control"
                    formControlName="fin">
        </div>
        <div class="company-modal__field">
            <label
                    for="companyNameI"
                    class="my-details__form__fields__field__label"
            >{{'MY_DETAILS.COMPANY_NAME' | translate}}</label>
            <input
                    id="companyNameI"
                    class="form-control"
                    formControlName="name">
        </div>
    </form>
</ng-template>

<ng-template #userModal>
    <app-fnp-modal
            [title]="'USERS.ADD_USER'"
            [contentTemplate]="userModalContent"
            [saveAction]="true"
            [modalError]="modalError"
            [invalidForm]="userFormGroup.invalid"
            (submitAction$)="addUser()"
            (cancelAction$)="clearErrorMessage()"
    ></app-fnp-modal>
</ng-template>


<ng-template #userModalContent>
    <form
            class="company-modal"
            [formGroup]="userFormGroup">
        <h2 class="company-modal__group-title">{{'USERS.COMPANY_DETAILS' | translate}}</h2>
        <div class="company-modal__field mb-4">
            <label
                    for="userFinCodeI"
                    class="my-details__form__fields__field__label"
            >{{'USERS.FIN_CODE' | translate}}</label>
            <input
                    lettersNumbersOnly
                    id="userFinCodeI"
                    class="form-control"
                    formControlName="finCode">
        </div>
        <h2 class="company-modal__group-title">{{'USERS.USER_DETAILS' | translate}}</h2>
        <div class="company-modal__users">
            <div class="company-modal__field me-4">
                <label
                        for="contactNameI"
                        class="my-details__form__fields__field__label"
                >{{'USERS.CONTACT_NAME' | translate}}</label>
                <input
                        id="contactNameI"
                        class="form-control"
                        formControlName="contactName">
            </div>
            <div class="company-modal__field">
                <label
                        for="emailAddressI"
                        class="my-details__form__fields__field__label"
                >{{'MY_DETAILS.EMAIL_ADDRESS' | translate}}</label>
                <input
                        id="emailAddressI"
                        type="email"
                        class="form-control"
                        formControlName="email">
            </div>
        </div>
        <div class="mt-3 company-modal__checkbox">
            <input id="admin" type="checkbox" class="form-check fnp-checkbox me-3 ms-1" formControlName="administrator">
            <label for="admin">{{"USERS.ADMINISTRATOR" | translate}}</label>
        </div>
    </form>
</ng-template>


<ng-template #deleteModalTemplate>
    <app-fnp-modal
            [title]="'USERS.DELETE'"
            [contentTemplate]="deleteConfirmationTemplate"
            [saveAction]="true"
            [cancelButtonLabel]="'GLOBAL.NO'"
            [submitButtonLabel]="'GLOBAL.YES'"
            (submitAction$)="performDeleteUsers()"
    ></app-fnp-modal>
</ng-template>

<ng-template #deleteConfirmationTemplate>
    <div class="delete-modal">
        <div class="delete-modal__icon">
            <fa-icon [icon]="['far', 'trash-can']"></fa-icon>
        </div>
        <div class="delete-modal__txt">
            <p>{{'USERS.DELETE_CONFIRMATION' | translate}}</p>
        </div>
    </div>
</ng-template>