import {Directive, HostBinding, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FeatureFlagService} from '../../services/feature-flag.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit, OnDestroy {

    @Input('appFeatureFlag')
    featureFlagName!: string;
    @Input()
    keepSpace? = false;

    @HostBinding('style.display')
    public display: string;
    @HostBinding('style.visibility')
    public visibility: string;

    private _featureFlagService: FeatureFlagService = inject(FeatureFlagService);
    private _forceReload: Subscription;

    private readonly DISPLAY = 'block';
    private readonly NO_DISPLAY = 'none';
    private readonly VISIBLE = 'visible';
    private readonly HIDDEN = 'hidden';

    public ngOnInit(): void {
        if (!this.featureFlagName) console.log('FEATURE FLAG NOT REGISTERED!');
        this.registerFlag();
        this.checkState();
    }

    public ngOnDestroy(): void {
        this._forceReload.unsubscribe();
    }

    private registerFlag(): void {
        this._forceReload = this._featureFlagService.register(this.featureFlagName).subscribe({
                next: () => this.checkState(),
                error: (e) => console.log(e)
            }
        );
        console.log(this._forceReload);
    }

    private checkState(): void {
        const activeState = this._featureFlagService.getActiveState(this.featureFlagName);
        if (this.keepSpace) {
            this.toggleVisibility(activeState);
        } else {
            this.toggleDisplay(activeState);
        }
    }

    private toggleDisplay(display: boolean): void {
        this.display = display ? this.DISPLAY : this.NO_DISPLAY;
    }

    private toggleVisibility(display: boolean): void {
        this.visibility = display ? this.VISIBLE : this.HIDDEN;
    }

}
