import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';
import {User, UsersList} from '../models/user';
import {SearchCriteria} from '../models/search-criteria';
import {UrlBuilder} from '../utils/other/url-builder';
import {PasswordResetDto} from '../models/password-reset-dto';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _httpClient: HttpClient = inject(HttpClient);

    public getUserByEmail(email: string): Observable<User> {
        return this._httpClient.get<User>(environment.apiUrl + '/users/' + email);
    }

    public getUsers(searchCriteria?: SearchCriteria): Observable<UsersList> {
        const url = UrlBuilder.buildUrl('/users', searchCriteria);
        return this._httpClient.get<UsersList>(url);
    }

    public postUser(user: User): Observable<User> {
        return this._httpClient.post<User>(environment.apiUrl + '/users', user);
    }

    public patchUser(email: string, contactName: string): Observable<void> {
        return this._httpClient.patch<void>(environment.apiUrl + `/users/${email}`, contactName);
    }

    public deleteUser(id: string): Observable<void> {
        return this._httpClient.delete<void>(environment.apiUrl + `/users/${id}`);
    }

    public updatePassword(username: string, passwordResetDto: PasswordResetDto): Observable<void> {
        return this._httpClient.put<void>(environment.apiUrl + `/users/${username}/password-reset`, passwordResetDto);
    }

}