import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {Market} from '../../../models/market';
import {RegistrationService} from '../../../services/registration.service';
import {FnpLanguageService} from '../../../services/fnp-language.service';
import {FnpDialogComponent, FnpDialogContent} from '../../shared/fnp-dialog/fnp-dialog.component';
import {getMessageFromError} from '../../../utils/other/utilities';
import {AVAILABLE_MARKETS} from '../../../../config/markets/available-markets';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    @ViewChild('successfullyRegistered') successfullyRegistered: FnpDialogComponent;
    @ViewChild('wrongFinCode') wrongFinCode: FnpDialogComponent;
    @ViewChild('emailRegistered') emailRegistered: FnpDialogComponent;
    @ViewChild('generalError') generalError: FnpDialogComponent;

    private router = inject(Router);
    private formBuilder = inject(FormBuilder);
    private regService = inject(RegistrationService);
    private _route: ActivatedRoute = inject(ActivatedRoute);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public registrationFormGroup: FormGroup;
    public availableMarkets: Market[];

    public successParams: FnpDialogContent = {
        icon: 'success',
        title: 'REGISTER.SUCCESS_DIALOG.TITLE',
        text1: 'REGISTER.SUCCESS_DIALOG.TEXT1',
        text2: 'REGISTER.SUCCESS_DIALOG.TEXT2',
        text3: 'REGISTER.SUCCESS_DIALOG.TEXT3',
        buttonLabel: 'REGISTER.SUCCESS_DIALOG.BUTTON_LABEL'
    };
    public wrongFinParams: FnpDialogContent = {
        icon: 'warning',
        title: 'REGISTER.WRONG_FIN_DIALOG.TITLE',
        text1: 'REGISTER.WRONG_FIN_DIALOG.TEXT1',
        text2: 'REGISTER.WRONG_FIN_DIALOG.TEXT2',
        buttonLabel: 'REGISTER.WRONG_FIN_DIALOG.BUTTON_LABEL'
    };
    public emailRegisteredParams: FnpDialogContent = {
        icon: 'warning',
        title: 'REGISTER.EMAIL_REGISTERED_DIALOG.TITLE',
        text1: 'REGISTER.EMAIL_REGISTERED_DIALOG.TEXT1',
        text2: 'REGISTER.EMAIL_REGISTERED_DIALOG.TEXT2',
        buttonLabel: 'REGISTER.EMAIL_REGISTERED_DIALOG.BUTTON_LABEL'
    };
    public generalErrorParams: FnpDialogContent = {
        icon: 'error',
        title: 'ERR_DIALOG.TITLE',
        text1: 'ERR_DIALOG.DESCRIPTION',
        buttonLabel: 'ERR_DIALOG.BUTTON_LABEL'
    };

    constructor() {
        this.setLocale();
        this.availableMarkets = AVAILABLE_MARKETS;
    }

    public ngOnInit(): void {
        // TODO add market validator after AUS
        this.registrationFormGroup = this.formBuilder.group({
            finCode: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]+$')]],
            contactName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            marketId: [null, []]
        });

        // TODO add control for new markets:
        //    marketId: [null, Validators.required]
    }

    private setLocale(): void {
        const lang = this._route.snapshot.queryParams['lang'];
        setTimeout(() => this._languageService.use(lang), 300);
    }

    public onSubmit(): void {
        const regUser: User = {...this.registrationFormGroup.value};
        // TODO remove after AUS
        regUser.marketId = 1;
        this.regService.registerUser(regUser).subscribe({
            next: () => {
                this.successfullyRegistered.open();
            },
            error: (error) => {
                const errorMessage = getMessageFromError(error);
                if (errorMessage === 'EC0001') {
                    this.wrongFinCode.open();
                } else if (errorMessage === 'EC0002') {
                    this.emailRegistered.open();
                } else {
                    this.generalErrorParams.text1 = 'ERROR.' + errorMessage;
                    this.generalError.open();
                }
            }
        });
    }

    public toLoginPage(): void {
        this.router.navigate(['login']).then();
    }

    public resetForm(): void {
        this.registrationFormGroup.reset();
    }

    public get finCode(): AbstractControl {
        return this.registrationFormGroup.get('finCode');
    }

    public get contactName(): AbstractControl {
        return this.registrationFormGroup.get('contactName');
    }

    public get email(): AbstractControl {
        return this.registrationFormGroup.get('email');
    }

    public get marketId(): AbstractControl {
        return this.registrationFormGroup.get('marketId');
    }
}
